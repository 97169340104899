.bg-img {
  background-image: url(../src/assets/bg_top.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-solutions {
  background-image: url(../src/assets/bg_services.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-footer {
  background-image: url(../src/assets/bg_footer.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -1;
}

.slick-slide {
  padding: 20px;
}

.slick-list {
  padding: -20px;
}

.active {
  font-weight: 700;
}

.text-outline {
  color: #D5D9E5;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.text-gradient {
  background: linear-gradient(97.31deg, #92DEED 30.1%, #FFFFFF 94.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-menu {
  background: linear-gradient(110.31deg, #92DEED 50.1%, #FFFFFF 84.6%);
}

.service-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%),
    linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.services-box {
  height: 450px;
}

.bg-team-1 {
  background-image: url(../src/assets/team_1.png);
  background-position: center;
  background-size: cover;
  width: 424px;
  height: 400px;
}

@media (max-width: 1024px) {

  .services-box {
    height: auto;
  }
}

.fade-in-text {
  animation: fadeIn 1s infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}


.logo_slider {
  overflow: hidden;
  margin: auto;
}

.logo_slider {
  overflow: hidden;
}

.logo_slider ul {
  list-style: none;
  animation: mymove 8s linear infinite;
}

@keyframes mymove {

  0% {
    transform: 0;
  }

  100% {
    transform: translateX(-100%);
  }

  0% {
    transform: translateX(100%);
  }

}